// DailyControllingTable.tsx
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { dateToSqlDate, getTimespans } from "../../helpers/dateFuncs";
import fetchData from "../../helpers/fetchData";
import { FilterContext, IFilterState } from "../../contexts/FilterContext";
import LoadingSpinner from "../LoadingSpinner";
import { formatDataValue } from "../../helpers/formatFuncs";
import TableWidget from "../../helpers/tableBuilder";

const timespans = getTimespans();
// customer, source, date, client_id, SUM(count) as count

type clientDataResponse = {
  client_id: number;
  client_name: string;
  lead_price: number;
};

type apiResponse = {
  source: string;
  date: string;
  client_id: number;
  count: number;
};

type campaignsApiResponse = {
  client_id: number;
  name: string;
  quota: number;
  quota_used: number;
};

type leadData = {
  leads: number;
  turnover: number;
};

type aggregatedData = {
  [source: string]: {
    timespans: {
      [timespan: string]: {
        [client_id: number]: leadData;
      };
    };
    available_clients: number[];
  };
};

type soldLeadsData = {
  [timespan: string]: {
    [client_id: number]: leadData;
  };
};

type clientData = {
  [client_id: number]: {
    client_name: string;
    lead_price: number;
  };
};

export const sources: {
  [customer: string]: { [source_key: string]: string }[];
} = {
  ft: [
    { all: "Alles" },
    { traffic: "Traffic" },
    { sam: "SAMs" },
    { nl: "Newsletter" },
    { push: "Push" },
    { welcome_campaign: "Welcome Campaign" },
    { pdf_analysis: "PDF Zonen" },
    { doi_zone: "DOI Zone" },
    { aktiencheck: "Aktiencheck" },
    { boerse_global: "Börse Global" },
    { ariva: "Ariva" },
    { criteo: "Criteo" },
    { sharedeals: "Sharedeals" },
    { sharewise: "Sharewise" },
    { dnv: "DNV" },
    { vnr_duplicates: "VNR Dubletten" },
    { other: "Unbekannt" },
  ],
  bg: [
    { all: "Alles" },
    { sam: "SAMs" },
    { boerse_global: "Börse Global" },
    { aktiencheck: "Aktiencheck" },
    { aktiencheck_video: "Aktiencheck Video" },
    { boerse_express: "Börse Express" },
    { finanznachrichten_bg: "FN (Boerse-Global)" },
    { finanznachrichten_boersia: "FN (Boersia)" },
    { finanznachrichten_wo_distributor: "FN (Coreg Feeds)" },
    { ariva: "Ariva" },
    { stock_world: "Stock World" },
    { aktien_global: "Aktien Global" },
    { aktiencheck_video: "Aktiencheck Video" },
    { gurupress: "Gurupress" },
    { boersia: "Boersia" },
    { boersify: "Boersify" },
    { other: "Unbekannt" },
  ],
};

export const timespanLabels = [
  "Heute",
  "Average",
  "Gestern",
  "Diese Woche",
  "Letzte Woche",
  "Dieser Monat",
  "Letzter Monat",
  "Forecast",
];

const getSourceMap = (customer: string): { [source: string]: string } => {
  const customerSources = sources[customer];
  return customerSources.reduce((acc, source) => {
    const key = Object.keys(source)[0];
    acc[key] = source[key];
    return acc;
  }, {} as { [source: string]: string });
};

const getClients = async (customer: string): Promise<clientData> => {
  const clientData = (await fetchData<clientDataResponse[]>(
    "/api/daily-controlling/lead-clients/" + customer,
    {}
  )) as clientDataResponse[];
  const clientDataMap: clientData = {
    0: { client_name: "Insgesamt", lead_price: 0 },
  };
  clientData.forEach((client) => {
    clientDataMap[client.client_id] = {
      client_name: client.client_name,
      lead_price: client.lead_price,
    };
  });
  return clientDataMap;
};

const getCampaigns = async (): Promise<campaignsApiResponse[]> => {
  return (await fetchData<campaignsApiResponse[]>(
    "/api/daily-controlling/campaigns/ft",
    {}
  )) as campaignsApiResponse[];
};

const getData: (
  filterState: IFilterState,
  customer: string
) => Promise<aggregatedData> = async (
  filterState: IFilterState,
  customer: string
) => {
  if (!filterState.startDate || !filterState.endDate) {
    throw new Error("No start date or end date provided");
  }
  const clientData = await getClients(customer);
  const options = {
    start_date: dateToSqlDate(filterState.startDate),
    end_date: dateToSqlDate(filterState.endDate),
    lead_status: filterState.leadStatus,
  };
  const data = (await fetchData<apiResponse[]>(
    `/api/daily-controlling/data/${customer}`,
    options
  )) as Record<string, any>[];
  const aggregatedData: aggregatedData = {};
  sources[customer].forEach((source) => {
    const sourceKey = Object.keys(source)[0];
    aggregatedData[sourceKey] = {
      timespans: {},
      available_clients: [0],
    };
    timespans.forEach((timespan) => {
      aggregatedData[sourceKey].timespans[timespan.label] = {
        0: {
          leads: 0,
          turnover: 0,
        },
      };
      data.forEach((row) => {
        if (row.source === sourceKey) {
          if (timespan.check(new Date(row.date))) {
            if (
              !aggregatedData[sourceKey].timespans[timespan.label][
                row.client_id
              ]
            ) {
              aggregatedData[sourceKey].timespans[timespan.label][
                row.client_id
              ] = {
                leads: 0,
                turnover: 0,
              };
            }
            // only new leads are being paid
            let clientLeadPrice =
              filterState.leadStatus === "new"
                ? clientData[row.client_id]?.lead_price ?? null
                : 0;
            if (clientLeadPrice === null) {
              console.error(
                `No lead price found for client_id: ${row.client_id}`
              );
              clientLeadPrice = 0;
            }
            aggregatedData[sourceKey].timespans[timespan.label][
              row.client_id
            ].leads += row.count;
            aggregatedData[sourceKey].timespans[timespan.label][
              row.client_id
            ].turnover += row.count * clientLeadPrice;
            aggregatedData[sourceKey].timespans[timespan.label][0].leads +=
              row.count;
            aggregatedData[sourceKey].timespans[timespan.label][0].turnover +=
              row.count * clientLeadPrice;
            if (
              !aggregatedData[sourceKey]["available_clients"].includes(
                row.client_id
              )
            ) {
              aggregatedData[sourceKey]["available_clients"].push(
                row.client_id
              );
            }
          }
        }
      });
    });
  });
  return aggregatedData;
};

// return "all" data but only for clients with lead_price > 0
const getSoldLeads = async (
  filterState: IFilterState,
  customer: string
): Promise<soldLeadsData> => {
  const data = await getData(filterState, customer);
  const clientData = await getClients(customer);
  let soldLeads: soldLeadsData = {};

  timespans.forEach((timespan) => {
    soldLeads[timespan.label] = {};

    const allSourceData = data["all"].timespans[timespan.label];

    Object.keys(allSourceData).forEach((clientIdStr) => {
      const clientId = parseInt(clientIdStr, 10);
      const client = clientData[clientId];

      if (client && client.lead_price > 0) {
        soldLeads[timespan.label][clientId] = {
          leads: allSourceData[clientId].leads,
          turnover: allSourceData[clientId].turnover,
        };
      }
    });

    // Add total for each timespan
    soldLeads[timespan.label][0] = Object.values(
      soldLeads[timespan.label]
    ).reduce(
      (acc, curr) => ({
        leads: acc.leads + curr.leads,
        turnover: acc.turnover + curr.turnover,
      }),
      { leads: 0, turnover: 0 }
    );
  });

  return soldLeads;
};

const renderCell = (leads: number, turnover: number) => {
  return {
    __html: `<strong>${leads}</strong> (${formatDataValue(
      turnover,
      "number",
      0,
      "€"
    )})`,
  };
};

export const DCCampaignsTable: React.FC = () => {
  const [data, setData] = useState<campaignsApiResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        setData(await getCampaigns());
        setLoading(false);
      } catch (err: any) {
        console.error("Error fetching data:", err);
        setError(err);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <TableWidget<campaignsApiResponse>
      getData={getCampaigns}
      detailsHeaderMapping={{
        name: {
          name: "Kampagne",
          type: "string",
        },
        quota: {
          name: "Kontingent",
          type: "number",
          digits: 0,
        },
        quota_used: {
          name: "Leads",
          type: "number",
          digits: 0,
        },
      }}
      initialSortColumn="quota_used"
      excelDownloadName="lead-campaigns"
      sortOrder="desc"
    />
  );
};

export const DCSoldLeadsTable: React.FC<{ customer: string }> = ({
  customer,
}) => {
  const { filterState } = useContext(FilterContext)!;
  if (!filterState.startDate || !filterState.endDate) {
    throw new Error("No start date or end date provided");
  }
  const [data, setData] = useState<soldLeadsData>({});
  const [clientData, setClientData] = useState<clientData>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const data = await getSoldLeads(filterState, customer);
        const clientData = await getClients(customer);

        const updatedData = { ...data };

        // Compute Average and Forecast
        const today = new Date();
        const dayOfMonth = today.getDate();
        const daysInMonth = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        ).getDate();

        // Add "temp_last_5_days" timespan if not already present
        if (dayOfMonth < 5 && !updatedData["temp_last_5_days"]) {
          // Fetch data for the last 5 days
          const lastFiveDaysStart = new Date(today);
          lastFiveDaysStart.setDate(lastFiveDaysStart.getDate() - 4);
          // You may need to adjust your data fetching logic to include this period
        }

        const useLastFiveDays = dayOfMonth < 5;
        const relevantTimespanLabel = useLastFiveDays
          ? "temp_last_5_days"
          : "Dieser Monat";
        const relevantDays = useLastFiveDays ? 5 : dayOfMonth;

        updatedData["Average"] = {};
        updatedData["Forecast"] = {};

        const clientIds = new Set<number>();
        Object.keys(updatedData[relevantTimespanLabel]).forEach(
          (clientIdStr) => {
            clientIds.add(Number(clientIdStr));
          }
        );

        clientIds.forEach((clientId) => {
          const relevantData = updatedData[relevantTimespanLabel][clientId] || {
            leads: 0,
            turnover: 0,
          };
          const avgLeadsPerDay = relevantData.leads / relevantDays;
          const avgTurnoverPerDay = relevantData.turnover / relevantDays;

          // Forecast
          const forecastLeads = avgLeadsPerDay * daysInMonth;
          const forecastTurnover = avgTurnoverPerDay * daysInMonth;

          updatedData["Average"][clientId] = {
            leads: avgLeadsPerDay,
            turnover: avgTurnoverPerDay,
          };
          updatedData["Forecast"][clientId] = {
            leads: forecastLeads,
            turnover: forecastTurnover,
          };
        });

        setData(updatedData);
        setClientData(clientData);
        setLoading(false);
      } catch (err: any) {
        console.error("Error fetching data:", err);
        setError(err);
        setLoading(false);
      }
    };
    fetchData();
  }, [filterState, customer]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  const renderCell = (leads: number, turnover: number) => {
    return {
      __html: `<strong>${leads}</strong> (${formatDataValue(
        turnover,
        "number",
        0,
        "€"
      )})`,
    };
  };

  // Get unique client IDs across all timespans
  const allClientIds = Array.from(
    new Set(
      Object.values(data).flatMap((timespanData) =>
        Object.keys(timespanData || {}).map(Number)
      )
    )
  ).sort((a, b) => a - b);

  return (
    <Box bgcolor="#fff">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell
                className="!text-md !font-bold"
                sx={{ minWidth: 120, width: 120 }}
              >
                Kunde
              </TableCell>
              {timespanLabels.map((timespanLabel) => (
                <TableCell
                  key={timespanLabel}
                  className="!text-md !font-bold !p-0 !m-0"
                  sx={{ minWidth: 90, width: 90 }}
                >
                  {timespanLabel}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {allClientIds.map((clientId) => (
              <TableRow
                key={clientId}
                className={clientId === 0 ? "!bg-gray-200" : ""}
              >
                <TableCell
                  className={(clientId === 0 ? "!font-bold" : "") + "!text-md"}
                  sx={{ minWidth: 120, width: 120 }}
                >
                  {clientData[clientId]?.client_name ?? "Unbekannt"}
                </TableCell>
                {timespanLabels.map((timespanLabel) => (
                  <TableCell
                    key={timespanLabel}
                    className="!text-md !p-0 !m-0"
                    sx={{ minWidth: 90, width: 90 }}
                  >
                    <span
                      dangerouslySetInnerHTML={renderCell(
                        Math.round(data[timespanLabel]?.[clientId]?.leads ?? 0),
                        data[timespanLabel]?.[clientId]?.turnover ?? 0
                      )}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export const DailyControllingTable: React.FC<{ customer: string }> = ({
  customer,
}) => {
  const { filterState } = useContext(FilterContext)!;
  if (!filterState.startDate || !filterState.endDate) {
    throw new Error("No start date or end date provided");
  }
  const [data, setData] = useState<aggregatedData>({});
  const [clientData, setClientData] = useState<clientData>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const sourceMap = getSourceMap(customer);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const data = await getData(filterState, customer);
        const clientData = await getClients(customer);
        const updatedData = { ...data };

        // Compute Average and Forecast
        const today = new Date();
        const dayOfMonth = today.getDate();
        const daysInMonth = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        ).getDate();

        Object.keys(updatedData).forEach((source) => {
          const sourceData = updatedData[source];

          sourceData.timespans["Average"] = {};
          sourceData.timespans["Forecast"] = {};

          sourceData.available_clients.forEach((client_id) => {
            let totalLeads,
              totalTurnover,
              totalDays,
              avgPerDay,
              forecastLeads,
              forecastTurnover;

            // Get data for "Dieser Monat"
            const thisMonthData = sourceData.timespans["Dieser Monat"][
              client_id
            ] || {
              leads: 0,
              turnover: 0,
            };

            // Get data for "temp_last_5_days"
            const lastFiveDaysData = sourceData.timespans["temp_last_5_days"]?.[
              client_id
            ] || {
              leads: 0,
              turnover: 0,
            };

            if (dayOfMonth >= 5) {
              // Use "Dieser Monat" data
              totalLeads = thisMonthData.leads;
              totalTurnover = thisMonthData.turnover;
              totalDays = dayOfMonth;
            } else {
              // Use "temp_last_5_days" data
              totalLeads = lastFiveDaysData.leads;
              totalTurnover = lastFiveDaysData.turnover;
              totalDays = 5;
            }

            avgPerDay = totalLeads / totalDays;
            const avgTurnoverPerDay = totalTurnover / totalDays;

            // Forecast
            forecastLeads = avgPerDay * daysInMonth;
            forecastTurnover = avgTurnoverPerDay * daysInMonth;

            // Add to timespans
            sourceData.timespans["Average"][client_id] = {
              leads: avgPerDay,
              turnover: avgTurnoverPerDay,
            };

            sourceData.timespans["Forecast"][client_id] = {
              leads: forecastLeads,
              turnover: forecastTurnover,
            };
          });
        });

        setData(updatedData);
        setClientData(clientData);
        setLoading(false);
      } catch (err: any) {
        console.error("Error fetching data:", err);
        setError(err);
        setLoading(false);
      }
    };
    fetchData();
  }, [filterState, customer]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Box bgcolor="#fff">
      <TableContainer
        component={Paper}
        sx={{ maxHeight: "calc(100vh - 200px)", overflow: "auto" }}
      >
        <Table stickyHeader sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell
                className="!text-md !font-bold"
                sx={{
                  minWidth: 120,
                  width: 120,
                  position: "sticky",
                  left: 0,
                  zIndex: 3,
                  backgroundColor: "#fff",
                }}
              >
                Quelle
              </TableCell>
              {timespanLabels.map((timespanLabel) => (
                <TableCell
                  key={timespanLabel}
                  className="!text-md !font-bold !p-0 !m-0"
                  sx={{ minWidth: 90, width: 90 }}
                >
                  {timespanLabel}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(data).map((source) => (
              <TableRow key={source}>
                <TableCell
                  colSpan={timespanLabels.length + 1}
                  className="!m-0 !p-0"
                >
                  <Table>
                    <TableHead>
                      <TableRow className="!bg-gray-200">
                        <TableCell
                          className="!font-bold"
                          sx={{
                            minWidth: 120,
                            width: 120,
                            position: "sticky",
                            left: 0,
                            zIndex: 2,
                            backgroundColor: "#e5e7eb",
                          }}
                        >
                          {sourceMap[source] ?? source}
                        </TableCell>
                        {timespanLabels.map((timespanLabel) => (
                          <TableCell
                            key={timespanLabel}
                            className="!pl-0 !ml-0"
                            sx={{ minWidth: 90, width: 90 }}
                          >
                            <span
                              dangerouslySetInnerHTML={renderCell(
                                Math.round(
                                  data[source].timespans[timespanLabel][0]
                                    ?.leads ?? 0
                                ),
                                data[source].timespans[timespanLabel][0]
                                  ?.turnover ?? 0
                              )}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data[source]?.available_clients?.map(
                        (client_id) =>
                          client_id !== 0 && (
                            <TableRow key={`${source}-client-${client_id}`}>
                              <TableCell
                                sx={{
                                  position: "sticky",
                                  left: 0,
                                  zIndex: 1,
                                  backgroundColor: "#fff",
                                }}
                              >
                                {clientData[client_id]?.client_name ??
                                  "Unknown Client"}
                              </TableCell>
                              {timespanLabels.map((timespanLabel) => (
                                <TableCell
                                  key={timespanLabel}
                                  className="!m-0 !p-0"
                                >
                                  <span
                                    dangerouslySetInnerHTML={renderCell(
                                      Math.round(
                                        data[source].timespans[timespanLabel][
                                          client_id
                                        ]?.leads ?? 0
                                      ),
                                      data[source].timespans[timespanLabel][
                                        client_id
                                      ]?.turnover ?? 0
                                    )}
                                  />
                                </TableCell>
                              ))}
                            </TableRow>
                          )
                      )}
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DailyControllingTable;
